import { Injectable } from '@angular/core';
import { CurrentUserContext, CurrentUserService, UserType } from '@rma/accounts/user';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import {
  BillingUserContext,
  BillingUserContextService,
  CouponModel,
  PriceType,
  SubscriptionLineItemModel,
  SubscriptionModel,
} from '@rma/subscriptions/public-api/da-billing-context';
import { differenceInDays } from 'date-fns';
import { cache } from 'decorator-cache-getter';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SubscriptionApiService } from '../data-access/subscription-api.service';
import { SubscriptionTiers } from '../index';
import { CurrentSubscription, SubscriptionSummary } from './current-subscription.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentSubscriptionService {
  public constructor(
    private readonly billingUserContextService: BillingUserContextService,
    private readonly subscriptionApiService: SubscriptionApiService,
    private readonly userService: CurrentUserService,
  ) {}

  @cache
  public get currentSubscriptionSummary$(): Observable<SubscriptionSummary | null> {
    return this.userService.currentUserContext$.pipe(
      switchMap((x) => this.loadSubscriptionSummary(x)),
      rmaShareReplay(),
    );
  }

  @cache
  public get rawCurrentSubscription$(): Observable<SubscriptionModel | null> {
    return this.billingUserContextService.userContext$.pipe(
      switchMap((context) =>
        context
          ? this.subscriptionApiService.getCurrentSubscription(context.productUserType, context.productUserGroupingType, context.code)
          : of(null),
      ),
      rmaShareReplay(),
    );
  }

  @cache
  public get currentSubscription$(): Observable<CurrentSubscription | null> {
    const currentSubscription$ = this.rawCurrentSubscription$.pipe(
      catchError((error) => {
        if (error.Status === 404) {
          return of(null);
        }
        throw error;
      }),
    );
    return combineLatest([currentSubscription$, this.currentSubscriptionSummary$, this.billingUserContextService.userContext$]).pipe(
      map(([x, y, z]) => (y && z ? mapCurrentSubscription(x, y, z) : null)),
    );
  }

  private loadSubscriptionSummary(user: CurrentUserContext | null): Observable<SubscriptionSummary | null> {
    if (user === null || user.userType === UserType.Consumer) {
      return of(null);
    }

    if (user.userType === UserType.Agent) {
      return this.subscriptionApiService.getCurrentAgentSubscriptionSummary();
    } else {
      return this.subscriptionApiService.getCurrentAgencySubscriptionSummary(user.profileCode);
    }
  }
}

function mapCurrentSubscription(
  currentSubscription: SubscriptionModel | null,
  summary: SubscriptionSummary,
  billingContext: BillingUserContext,
): CurrentSubscription {
  const lineItem = currentSubscription?.subscriptionLineItems?.find((x) => x.priceType === PriceType.Regular);
  const subscriptionSchedule = currentSubscription?.subscriptionSchedule;

  const trialDays = currentSubscription?.trialEnd ? differenceInDays(new Date(currentSubscription.trialEnd), new Date()) : 0;

  const hasNewPlan = !!currentSubscription?.subscriptionSchedule?.startDate;
  const hasCancelled = !!currentSubscription?.cancelAt && !hasNewPlan;

  const unsubscribeUrl = !hasCancelled
    ? summary.subscriptionTier !== SubscriptionTiers.Starter
      ? 'billing/downgrade'
      : 'billing/unsubscribe'
    : undefined;

  return {
    id: currentSubscription?.id,
    name: summary.displayName,
    tier: summary.subscriptionTier,
    productUserType: billingContext.productUserType,
    productUserTypeGrouping: billingContext.productUserGroupingType,
    isFree: summary.isFreeSubscription,
    isSubscribed: !!currentSubscription?.isActive && !hasCancelled,
    isProvided: !summary.isOwnSubscription,

    price: calculatePrice(lineItem, currentSubscription?.coupon),
    period: lineItem?.interval,
    productId: lineItem?.productId,

    teamMembers: lineItem?.quantity,
    onTrial: trialDays > 0,
    trialDays: trialDays ?? undefined,
    hasCancelled,
    newPlan: subscriptionSchedule?.subscriptionScheduleItems?.[0]?.productName,
    subscriptionEnd: currentSubscription?.cancelAt ?? subscriptionSchedule?.startDate ?? currentSubscription?.currentPeriodEnd,
    unsubscribeUrl,
    startedWithReverseTrial: currentSubscription?.startedWithReverseTrial,
  };
}

function calculatePrice(price: SubscriptionLineItemModel | undefined, coupon?: CouponModel): number {
  if (!price) {
    return 0;
  }

  let priceAmount = price.priceAmount ?? 0;

  if (coupon?.amountOff) {
    priceAmount -= coupon?.amountOff;
  }

  if (coupon?.percentOff) {
    priceAmount *= (100 - coupon.percentOff) / 100;
  }

  return priceAmount / 100;
}
